import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { Button } from "./components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "./components/ui/card";
import { Badge } from "./components/ui/badge";
import { Origami, ChevronRight } from 'lucide-react';
import { emailTemplates } from './emailTemplates'
import MatomoTracker from './components/MatomoTracker';
import Editor from './Editor';
import './App.scss';

const Navbar = () => {
  const location = useLocation();

  return (
    <nav className="fixed top-0 left-0 right-0 bg-background border-b border-border z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <Link to="/" className="flex items-center space-x-2">
          <Origami className="h-6 w-6" />
          <span className="font-bold text-lg">OrigamiSign</span>
        </Link>
        <div className="flex space-x-4">
          <Button variant={location.pathname.includes('/editor') ? 'default' : 'ghost'} asChild>
            <Link to="/editor">Editor</Link>
          </Button>
          <Button variant={location.pathname === '/templates' ? 'default' : 'ghost'} asChild>
            <Link to="/templates">Templates</Link>
          </Button>
        </div>
      </div>
    </nav>
  );
};

const Footer = () => {
  return (
    <footer className="bg-background border-t border-border mt-8 py-4">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center space-y-3">
          <div className="flex items-center space-x-2">
            <Origami className="h-12 w-12" />
            <span className="font-bold"></span>
          </div>
          <div className="flex space-x-4">
            <Link to="/" className="text-sm text-muted-foreground hover:text-foreground">Home</Link>
            <Link to="/editor" className="text-sm text-muted-foreground hover:text-foreground">Editor</Link>
            <Link to="/templates" className="text-sm text-muted-foreground hover:text-foreground">Templates</Link>
          </div>
          <div className="text-sm text-muted-foreground mt-9">
            Created by Vitali Pisani 
          </div>
        </div>
      </div>
    </footer>
  );
};

const Templates = () => {
  const navigate = useNavigate();

  const handleCustomize = (templateId) => {
    navigate(`/editor/${templateId}`);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Choose a Template</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        {emailTemplates.map((template) => (
          <Card key={template.id}>
            <CardHeader>
              <CardTitle>{template.name}</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-48 overflow-hidden">
                <iframe
                  title={`preview-${template.id}`}
                  srcDoc={`<style>${template.css}</style>${template.html}`}
                  className="w-full h-full border-none"
                />
              </div>
            </CardContent>
            <CardFooter>
              <Button onClick={() => handleCustomize(template.id)} className="w-full button-cutomize">Customize</Button>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto p-4 max-w-3xl">
      <div className="text-center mb-12">
        <Origami className="h-16 w-16 mx-auto mb-6" />
        <h1 className="text-4xl font-bold mb-4">OrigamiSign</h1>
        <Badge variant="secondary" className="mb-4">Beta Version 1.5</Badge>
        <p className="text-xl mb-8">Create professional email signatures in minutes</p>
        <p className="text-lg mb-8">
          OrigamiSign is a simple, powerful tool for crafting beautiful email signatures. 
          Whether you're starting from scratch or customizing a template, we make it easy 
          to create signatures that leave a lasting impression.
        </p>
        <p className="text-sm text-muted-foreground mb-8">
          We're proud to be the first to use AI for email signature creation, revolutionizing the way you design your professional identity.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <Card>
          <CardHeader>
            <CardTitle>Create Signature with AI <Badge variant="secondary" className="">New</Badge></CardTitle>
          </CardHeader>
          <CardContent>
            <p>Let AI create a unique signature based on your description.</p>
          </CardContent>
          <CardFooter>
            <Button onClick={() => navigate('/editor?ai=true')} className="w-full">AI Create</Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Create New Signature</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Start from scratch and design your own unique email signature.</p>
          </CardContent>
          <CardFooter>
            <Button onClick={() => navigate('/editor')} className="w-full">Create New</Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Choose from Templates</CardTitle>
          </CardHeader>
          <CardContent>
            <p>Select from our pre-designed templates and customize to your liking.</p>
          </CardContent>
          <CardFooter>
            <Button onClick={() => navigate('/templates')} className="w-full">View Templates</Button>
          </CardFooter>
        </Card>
       
      </div>
    </div>
  );
};

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow container mx-auto px-4 pt-16 pb-8 linear">
        {children}
      </main>
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <div className="dark">
      <Router>
        <MatomoTracker />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/editor" element={<Editor />} />
            <Route path="/editor/:templateId" element={<Editor />} />
            <Route path="/templates" element={<Templates />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
};

export default App;