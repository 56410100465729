import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { css } from '@codemirror/lang-css';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs";
import { Button } from "./components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "./components/ui/card";
import { Badge } from "./components/ui/badge";
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle } from "./components/ui/drawer";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./components/ui/dropdown-menu";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./components/ui/accordion";
import { Sparkle , SplitSquareHorizontal, Code, Download, ChevronRight, Maximize, Minimize, Copy, LayoutPanelTop } from 'lucide-react';
import { emailTemplates } from './emailTemplates';
import generateEmailSignature from './utils/generateSignature';
import EmailSignatureChat from './EmailSignatureChat';


// Definire UnsavedChangesDrawer qui se non è in un file separato
const UnsavedChangesDrawer = ({ isOpen, onClose, onConfirm }) => (
    <Drawer open={isOpen} onClose={onClose}>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Unsaved Changes</DrawerTitle>
          <DrawerDescription>
            You have unsaved changes. Are you sure you want to leave?
          </DrawerDescription>
        </DrawerHeader>
        <DrawerFooter>
          <Button onClick={onConfirm}>Leave</Button>
          <DrawerClose asChild>
            <Button variant="outline">Cancel</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
  
  // Definire BreadcrumbNav qui se non è in un file separato
  const BreadcrumbNav = () => {
    const { templateId } = useParams();
    
    return (
      <nav aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2">
          <li><Link to="/" className="text-gray-500 hover:text-gray-700">Home</Link></li>
          <li><ChevronRight className="h-4 w-4 text-gray-500" /></li>
          <li><Link to="/editor" className="text-gray-500 hover:text-gray-700">Editor</Link></li>
          {templateId && (
            <>
              <li><ChevronRight className="h-4 w-4 text-gray-500" /></li>
              <li><span className="text-gray-900">{templateId}</span></li>
            </>
          )}
        </ol>
      </nav>
    );
  };

const Editor = () => {
  // State variables
  const [activeTab, setActiveTab] = useState("intelligent");
  const [htmlCode, setHtmlCode] = useState(`
    <section style="font-family: Arial, sans-serif;">
      <div>
        <h1>Title Example</h1>
      </div>
    </section>
  `);
  const [cssCode, setCssCode] = useState('');
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [showUnsavedDrawer, setShowUnsavedDrawer] = useState(false);
  const [intendedPath, setIntendedPath] = useState(null);
  const [isEditorFullscreen, setIsEditorFullscreen] = useState(false);
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0 });
  const [layout, setLayout] = useState('side-by-side');
  const [selectedFont, setSelectedFont] = useState('Arial, sans-serif');
  const [generationPrompt, setGenerationPrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [credits, setCredits] = useState(100);
  // Refs
  const previewRef = useRef(null);
  const lastGenerationTime = useRef(0);

  // Constants
  const THROTTLE_TIME = 10000; // 10 seconds
  const { templateId } = useParams();
  const navigate = useNavigate();

  const webSafeFonts = [
    { name: 'Arial', value: 'Arial, sans-serif' },
    { name: 'Helvetica', value: 'Helvetica, sans-serif' },
    { name: 'Times New Roman', value: 'Times New Roman, serif' },
    { name: 'Times', value: 'Times, serif' },
    { name: 'Courier New', value: 'Courier New, monospace' },
    { name: 'Courier', value: 'Courier, monospace' },
    { name: 'Verdana', value: 'Verdana, sans-serif' },
    { name: 'Georgia', value: 'Georgia, serif' },
    { name: 'Palatino', value: 'Palatino, serif' },
    { name: 'Garamond', value: 'Garamond, serif' },
    { name: 'Bookman', value: 'Bookman, serif' },
    { name: 'Comic Sans MS', value: '"Comic Sans MS", sans-serif' },
    { name: 'Trebuchet MS', value: '"Trebuchet MS", sans-serif' },
    { name: 'Arial Black', value: '"Arial Black", sans-serif' },
    { name: 'Impact', value: 'Impact, sans-serif' }
  ];

  // Derived state
  const combinedCode = `<style>${cssCode}</style>${htmlCode}`;

  // Effects
  useEffect(() => {
    if (templateId) {
      const template = emailTemplates.find(t => t.id === templateId);
      if (template) {
        setHtmlCode(template.html);
        setCssCode(template.css);
      }
    }
  }, [templateId]);

  // Event Handlers
  const handleCodeChange = useCallback((value, type) => {
    if (type === 'html') {
      setHtmlCode(value);
    } else if (type === 'css') {
      setCssCode(value);
    } else {
      const styleMatch = value.match(/<style>([\s\S]*?)<\/style>/);
      const htmlMatch = value.match(/<\/style>([\s\S]*)/);
      if (styleMatch) setCssCode(styleMatch[1]);
      if (htmlMatch) setHtmlCode(htmlMatch[1]);
    }
    setIsUnsavedChanges(true);
  }, []);

  const handlePreviewChange = (event) => {
    const newHtml = event.target.innerHTML;
    setHtmlCode(newHtml);
    setIsUnsavedChanges(true);
  };

  const handlePreviewKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      document.execCommand('insertLineBreak');
      handlePreviewChange({ target: previewRef.current });
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setContextMenu({
        visible: true,
        x: event.clientX,
        y: event.clientY
      });
    }
  };

  const insertElement = (tag) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const newElement = document.createElement(tag);
      if (tag === 'a') {
        newElement.href = '#';
      }
      newElement.textContent = range.toString() || `New ${tag.toUpperCase()}`;
      range.deleteContents();
      range.insertNode(newElement);
      handlePreviewChange({ target: previewRef.current });
    }
    setContextMenu({ visible: false, x: 0, y: 0 });
  };

  const handleDownload = () => {
    const blob = new Blob([combinedCode], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'signature.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    setIsUnsavedChanges(false);
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(combinedCode).then(() => {
      console.log('Code copied to clipboard');
    });
  };

  const handleNavigation = (path) => {
    if (isUnsavedChanges) {
      setIntendedPath(path);
      setShowUnsavedDrawer(true);
    } else {
      navigate(path);
    }
  };

  const handleConfirmNavigation = () => {
    setIsUnsavedChanges(false);
    setShowUnsavedDrawer(false);
    if (intendedPath) {
      navigate(intendedPath);
    }
  };

  const toggleEditorFullscreen = () => {
    setIsEditorFullscreen(!isEditorFullscreen);
  };

  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  const applyFont = (font) => {
    setSelectedFont(font);
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlCode, 'text/html');
    const section = doc.querySelector('section');
    if (section) {
      section.style.fontFamily = font;
      setHtmlCode(section.outerHTML);
    } else {
      const newHtml = `<section style="font-family: ${font};">${htmlCode}</section>`;
      setHtmlCode(newHtml);
    }
    setIsUnsavedChanges(true);
  };

  const handleGenerate = async () => {
    const now = Date.now();
    if (now - lastGenerationTime.current < THROTTLE_TIME) {
      setError(`Please wait ${Math.ceil((THROTTLE_TIME - (now - lastGenerationTime.current)) / 1000)} seconds before generating again.`);
      return;
    }

    setIsGenerating(true);
    setError(null);
    try {
      const generatedHTML = await generateEmailSignature(generationPrompt);
      setHtmlCode(generatedHTML);
      setCssCode('');
      setIsUnsavedChanges(true);
      lastGenerationTime.current = Date.now();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  // Render Functions
  const renderLayoutButtons = () => (
    <div className="flex space-x-2">
      <Button variant={layout === 'side-by-side' ? 'default' : 'outline'} onClick={() => handleLayoutChange('side-by-side')}>
        <LayoutPanelTop className="h-4 w-4 mr-2" />
        Side by Side
      </Button>
      <Button variant={layout === 'editor-top' ? 'default' : 'outline'} onClick={() => handleLayoutChange('editor-top')}>
        <LayoutPanelTop className="h-4 w-4 mr-2" />
        Editor Top
      </Button>
      <Button variant={layout === 'preview-top' ? 'default' : 'outline'} onClick={() => handleLayoutChange('preview-top')}>
        <LayoutPanelTop className="h-4 w-4 mr-2 rotate-180" />
        Preview Top
      </Button>
    </div>
  );

  const renderEditorContent = () => (
    <Card className={`h-full ${isEditorFullscreen ? 'fixed inset-0 z-50 bg-background' : ''}`}>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Editor</CardTitle>
        <Button variant="ghost" onClick={toggleEditorFullscreen}>
          {isEditorFullscreen ? <Minimize className="h-4 w-4" /> : <Maximize className="h-4 w-4" />}
        </Button>
      </CardHeader>
      <CardContent className="h-full">
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList>
            <TabsTrigger value="intelligent"><Code className="mr-2" />Intelligent</TabsTrigger>
            <TabsTrigger value="separate"><SplitSquareHorizontal className="mr-2" />Separate</TabsTrigger>
          </TabsList>
          <TabsContent value="intelligent" className="h-full">
            <CodeMirror
              value={combinedCode}
              height={isEditorFullscreen ? "calc(100vh - 150px)" : "400px"}
              extensions={[html(), css()]}
              onChange={(value) => handleCodeChange(value, 'combined')}
              theme="dark"
            />
          </TabsContent>
          <TabsContent value="separate" className="h-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 h-full">
              <div>
                <h4 className="mb-2">HTML</h4>
                <CodeMirror
                  value={htmlCode}
                  height={isEditorFullscreen ? "calc(100vh - 200px)" : "300px"}
                  extensions={[html()]}
                  onChange={(value) => handleCodeChange(value, 'html')}
                  theme="dark"
                />
              </div>
              <div>
                <h4 className="mb-2">CSS</h4>
                <CodeMirror
                  value={cssCode}
                  height={isEditorFullscreen ? "calc(100vh - 200px)" : "300px"}
                  extensions={[css()]}
                  onChange={(value) => handleCodeChange(value, 'css')}
                  theme="dark"
                />
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );

  const renderPreviewContent = () => (
    <Card className="h-full">
      <CardHeader>
        <CardTitle>Preview</CardTitle>
      </CardHeader>
      <CardContent className="h-full">
        <div
          ref={previewRef}
          contentEditable
          onInput={handlePreviewChange}
          onKeyDown={handlePreviewKeyDown}
          onContextMenu={handleContextMenu}
          dangerouslySetInnerHTML={{ __html: htmlCode }}
          style={{ width: '100%', height: '100%', minHeight: '400px', padding: '10px', overflowY: 'auto' }}
        />
      </CardContent>
    </Card>
  );

  const renderAdvancedOptions = () => (
    <Accordion type="single" collapsible className="w-full mb-4">
      <AccordionItem value="advanced-options">
        <AccordionTrigger>Advanced Options</AccordionTrigger>
        <AccordionContent>
          <Card>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <h4 className="mb-2 font-semibold">Select Font</h4>
                  <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                    {webSafeFonts.map((font) => (
                      <Button
                        key={font.name}
                        onClick={() => applyFont(font.value)}
                        variant={selectedFont === font.value ? 'default' : 'outline'}
                        style={{ fontFamily: font.value }}
                      >
                        {font.name}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
  const renderGenerateSignature = () => (
    <Card className="mb-4">
      <CardHeader>
        <CardTitle>Generate Signature</CardTitle>
      </CardHeader>
      <CardContent>
        <textarea
          value={generationPrompt}
          onChange={(e) => setGenerationPrompt(e.target.value)}
          placeholder="Describe the email signature you want to generate..."
          className="w-full p-2 border rounded"
          rows={4}
        />
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </CardContent>
      <CardFooter>
        <Button onClick={handleGenerate} disabled={isGenerating}>
          {isGenerating ? 'Generating...' : 'Generate Signature'}
        </Button>
      </CardFooter>
    </Card>
  );
  const handleSignatureGenerated = (generatedHTML) => {
    setHtmlCode(generatedHTML);
    setCssCode('');
    setIsUnsavedChanges(true);
  };


  const renderContent = () => {
    const editorAndPreviewContent = (
      <>

      
        {isEditorFullscreen ? (
          renderEditorContent()
        ) : (
          <>
          
            <div className={`grid ${layout === 'side-by-side' ? 'grid-cols-1 lg:grid-cols-2' : 'grid-cols-1'} gap-4 mt-4`}>
              {layout === 'preview-top' ? renderPreviewContent() : renderEditorContent()}
              {layout === 'preview-top' ? renderEditorContent() : renderPreviewContent()}
            </div>
            <div className="mt-4">
              <Card>
                <CardHeader>
                  <CardTitle> <Sparkle />Email Signature Generator with AI </CardTitle>
                </CardHeader>
                <CardContent>
                  <EmailSignatureChat 
                    onSignatureGenerated={handleSignatureGenerated}
                    credits={credits}
                    setCredits={setCredits}
                  />
                </CardContent>
              </Card>
            </div>
          </>
        )}
     
      </>
    );

    return editorAndPreviewContent;
  };

  return (
    <div className="container mx-auto p-4">
      <BreadcrumbNav />
      <div className="flex justify-between items-center mb-4">
        <span></span>
        <Badge variant="outline">{templateId ? `Editing: ${templateId}` : 'New Template'}</Badge>
        <span></span>
      </div>
      <div className="flex justify-between items-center mb-4">
        {!isEditorFullscreen && renderLayoutButtons()}
        <div className="flex space-x-2">
          <Button variant="outline" onClick={handleCopyCode}>
            <Copy className="mr-2" />Copy Code
          </Button>
          <Button variant="outline" onClick={handleDownload}>
            <Download className="mr-2" />Download
          </Button>
        </div>
      </div>
      {renderContent()}
      <UnsavedChangesDrawer
        isOpen={showUnsavedDrawer}
        onClose={() => setShowUnsavedDrawer(false)}
        onConfirm={handleConfirmNavigation}
      />
      {contextMenu.visible && (
        <DropdownMenu open={true} onOpenChange={() => setContextMenu({ visible: false, x: 0, y: 0 })}>
          <DropdownMenuContent className="w-56" style={{ position: 'fixed', top: contextMenu.y, left: contextMenu.x }}>
            <DropdownMenuItem onClick={() => insertElement('h1')}>Insert H1</DropdownMenuItem>
            <DropdownMenuItem onClick={() => insertElement('p')}>Insert P</DropdownMenuItem>
            <DropdownMenuItem onClick={() => insertElement('br')}>Insert BR</DropdownMenuItem>
            <DropdownMenuItem onClick={() => insertElement('a')}>Insert A</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
};

export default Editor;