// src/utils/generateSignature.js
// src/utils/generateSignature.js

import openai from '../config/openai';



async function generateEmailSignature(prompt) {
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `You are an HTML email signature generator. Create responsive HTML email signatures with these rules:
          1. Use only inline styles.
          2. Do not use any external fonts or resources.
          3. Ensure the design is responsive and works on various screen sizes.
          4. Use only web-safe fonts.
          5. Provide only the HTML code without any explanations or comments.
          6. Ensure all styles are inline and no separate <style> tag is used.
          7. Use table-based layout for maximum email client compatibility.
          9. Replace placeholder text (e.g., [Full Name], [Job Title], etc.) with appropriate content based on the user's description.
          10. Do not include social media icons or links unless specifically requested.`
        },
        {
          role: "user",
          content: `Generate a responsive HTML email signature based on this description: ${prompt}`
        }
      ],
      max_tokens: 1000,
      temperature: 0.7,
    });

    return response.choices[0].message.content.trim();
  } catch (error) {
    console.error("Error generating email signature:", error);
    if (error.response) {
      console.error(error.response.status, error.response.data);
      if (error.response.status === 429) {
        throw new Error("Rate limit exceeded. Please try again later.");
      } else if (error.response.status === 401) {
        throw new Error("Authentication error. Please check your API key.");
      }
    }
    throw new Error("Failed to generate signature. Please try again later.");
  }
}

export default generateEmailSignature;