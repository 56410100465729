// emailTemplates.js

export const emailTemplates = [
  {
    id: "purple-haze-minimal",
    name: "Purple Haze Minimal",
    html: `
<table style="font-family: 'Helvetica', sans-serif; border-collapse: collapse; width: 100%; max-width: 600px;">
  <tr>
    <td style="padding: 20px; background: linear-gradient(135deg, #4a148c, #7b1fa2);">
      <table style="width: 100%;">
        <tr>
          <td style="font-size: 22px; font-weight: bold; color: #e1bee7; padding-bottom: 10px;">Sophia Chen</td>
        </tr>
        <tr>
          <td style="font-size: 16px; color: #ce93d8; padding-bottom: 15px;">Data Scientist | AI Solutions</td>
        </tr>
        <tr>
          <td style="font-size: 14px; color: #b39ddb;">
            <a href="tel:+14445556666" style="color: #9575cd; text-decoration: none;">+1 (444) 555-6666</a><br>
            <a href="mailto:sophia@aisolutions.ai" style="color: #9575cd; text-decoration: none;">sophia@aisolutions.ai</a>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
    `,
    css: ''
  },
  {
    id: "oceanic-depths",
    name: "Oceanic Depths",
    html: `
<table style="font-family: 'Roboto', sans-serif; border-collapse: collapse; width: 100%; max-width: 600px;">
  <tr>
    <td style="padding: 20px; background: linear-gradient(135deg, #1a237e, #0d47a1);">
      <table style="width: 100%;">
        <tr>
          <td style="font-size: 24px; font-weight: bold; color: #bbdefb; padding-bottom: 10px;">Liam O'Connor</td>
        </tr>
        <tr>
          <td style="font-size: 16px; color: #90caf9; padding-bottom: 15px;">Marine Biologist | OceanTech</td>
        </tr>
        <tr>
          <td style="font-size: 14px; color: #64b5f6;">
            <a href="tel:+17778889999" style="color: #42a5f5; text-decoration: none;">+1 (777) 888-9999</a> |
            <a href="mailto:liam@oceantech.org" style="color: #42a5f5; text-decoration: none;">liam@oceantech.org</a>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
    `,
    css: ''
  },
  {
    id: "midnight-forest",
    name: "Midnight Forest",
    html: `
<table style="font-family: 'Segoe UI', sans-serif; border-collapse: collapse; width: 100%; max-width: 600px;">
  <tr>
    <td style="padding: 20px; background: linear-gradient(135deg, #1b5e20, #2e7d32);">
      <table style="width: 100%;">
        <tr>
          <td style="font-size: 22px; font-weight: bold; color: #c8e6c9; padding-bottom: 10px;">Emma Woodson</td>
        </tr>
        <tr>
          <td style="font-size: 15px; color: #a5d6a7; padding-bottom: 15px;">Environmental Lawyer | GreenLegal</td>
        </tr>
        <tr>
          <td style="font-size: 13px; color: #81c784;">
            <a href="tel:+13332224444" style="color: #66bb6a; text-decoration: none;">+1 (333) 222-4444</a><br>
            <a href="mailto:emma@greenlegal.com" style="color: #66bb6a; text-decoration: none;">emma@greenlegal.com</a>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
    `,
    css: ''
  },
  {
    id: "sleek-dark-future",
    name: "Sleek Dark Future",
    html: `
  <section style="font-family: 'Arial', sans-serif; background: linear-gradient(135deg, #1a1a1a, #2c2c2c); padding: 30px; border-radius: 15px; box-shadow: 0 10px 30px rgba(0,0,0,0.5);">
    <div style="background: rgba(255,255,255,0.05); backdrop-filter: blur(5px); border-radius: 10px; padding: 20px; border: 1px solid rgba(255,255,255,0.1); position: relative; overflow: hidden;">
      <div style="position: absolute; top: -50%; left: -50%; width: 200%; height: 200%; background: radial-gradient(circle, rgba(231,76,60,0.1) 0%, rgba(231,76,60,0) 70%); animation: pulse 4s ease-in-out infinite;"></div>
      <h1 style="font-size: 28px; color: #ffffff; margin: 0 0 10px; position: relative; z-index: 1; text-shadow: 0 0 10px rgba(231,76,60,0.7);">Morgan Sleek</h1>
      <p style="font-size: 18px; color: #bbbbbb; margin: 0 0 20px; position: relative; z-index: 1; letter-spacing: 1px;">Software Architect | DarkCode Inc.</p>
      <div style="display: flex; justify-content: space-between; align-items: center; background: rgba(0,0,0,0.3); border-radius: 8px; padding: 15px; position: relative; z-index: 1;">
        <a href="tel:+14567890123" style="color: #e74c3c; text-decoration: none; font-size: 16px; display: flex; align-items: center; transition: all 0.3s ease;">
          <span style="background: #2c2c2c; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">📞</span>
          +1 (456) 789-0123
        </a>
        <a href="mailto:morgan@darkcode.dev" style="color: #e74c3c; text-decoration: none; font-size: 16px; display: flex; align-items: center; transition: all 0.3s ease;">
          <span style="background: #2c2c2c; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">✉️</span>
          morgan@darkcode.dev
        </a>
      </div>
    </div>
  </section>
  <style>
  @keyframes pulse {
    0%, 100% { transform: scale(1); opacity: 0.5; }
    50% { transform: scale(1.1); opacity: 0.7; }
  }

  </style>
    `,
    css: ''
  },
  {
    id: "crystal-purple-glow",
    name: "Crystal Purple Glow",
    html: `
  <section style="font-family: 'Helvetica', sans-serif; background: linear-gradient(135deg, #4a148c, #7b1fa2); padding: 30px; border-radius: 15px; box-shadow: 0 10px 30px rgba(0,0,0,0.3);">
    <div style="background: rgba(255,255,255,0.1); backdrop-filter: blur(10px); border-radius: 10px; padding: 20px; border: 1px solid rgba(255,255,255,0.2);">
      <h1 style="font-size: 28px; color: #e1bee7; margin: 0 0 10px; text-shadow: 0 2px 4px rgba(0,0,0,0.3);">Sophia Chen</h1>
      <p style="font-size: 18px; color: #ce93d8; margin: 0 0 20px; letter-spacing: 1px;">Data Scientist | AI Solutions</p>
      <div style="display: flex; justify-content: space-between; align-items: center; background: rgba(0,0,0,0.2); border-radius: 8px; padding: 15px;">
        <a href="tel:+14445556666" style="color: #9575cd; text-decoration: none; font-size: 16px; display: flex; align-items: center;">
          <span style="background: #7b1fa2; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">📞</span>
          +1 (444) 555-6666
        </a>
        <a href="mailto:sophia@aisolutions.ai" style="color: #9575cd; text-decoration: none; font-size: 16px; display: flex; align-items: center;">
          <span style="background: #7b1fa2; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">✉️</span>
          sophia@aisolutions.ai
        </a>
      </div>
    </div>
  </section>
    `,
    css: ''
  },
  {
    id: "ocean-depths-hologram",
    name: "Ocean Depths Hologram",
    html: `
  <section style="font-family: 'Roboto', sans-serif; background: linear-gradient(135deg, #1a237e, #0d47a1); padding: 30px; border-radius: 15px; box-shadow: 0 10px 30px rgba(0,0,0,0.4);">
    <div style="background: rgba(255,255,255,0.1); backdrop-filter: blur(10px); border-radius: 10px; padding: 20px; border: 1px solid rgba(255,255,255,0.2); position: relative; overflow: hidden;">
      <div style="position: absolute; top: -50%; left: -50%; width: 200%; height: 200%; background: radial-gradient(circle, rgba(97,218,251,0.1) 0%, rgba(97,218,251,0) 70%); animation: waves 8s linear infinite;"></div>
      <h1 style="font-size: 28px; color: #bbdefb; margin: 0 0 10px; position: relative; z-index: 1; text-shadow: 0 0 10px rgba(97,218,251,0.7);">Liam O'Connor</h1>
      <p style="font-size: 18px; color: #90caf9; margin: 0 0 20px; position: relative; z-index: 1; letter-spacing: 1px;">Marine Biologist | OceanTech</p>
      <div style="display: flex; justify-content: space-between; align-items: center; background: rgba(0,0,0,0.2); border-radius: 8px; padding: 15px; position: relative; z-index: 1;">
        <a href="tel:+17778889999" style="color: #42a5f5; text-decoration: none; font-size: 16px; display: flex; align-items: center;">
          <span style="background: #0d47a1; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">📞</span>
          +1 (777) 888-9999
        </a>
        <a href="mailto:liam@oceantech.org" style="color: #42a5f5; text-decoration: none; font-size: 16px; display: flex; align-items: center;">
          <span style="background: #0d47a1; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">✉️</span>
          liam@oceantech.org
        </a>
      </div>
    </div>
  </section>
  <style>
  @keyframes waves {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
    `,
    css: ''
  },
  {
    id: "minimal-accent",
    name: "Minimal Accent",
    html: `
  <section style="font-family: 'Arial', sans-serif;">
    <table cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; border-collapse: collapse;">
      <tr>
        <td style="padding: 20px; background-color: #ffffff;">
          <table cellpadding="0" cellspacing="0" style="width: 100%;">
            <tr>
              <td style="padding-left: 15px; border-left: 4px solid #3498db;">
                <h2 style="margin: 0; font-size: 20px; color: #333333;">Jamie Modern</h2>
                <p style="margin: 5px 0 10px; font-size: 16px; color: #7f8c8d;">UX/UI Designer | FutureTech</p>
                <p style="margin: 0; font-size: 14px;">
                  <a href="tel:+11234567890" style="color: #3498db; text-decoration: none;">📱 (123) 456-7890</a>
                </p>
                <p style="margin: 5px 0 0; font-size: 14px;">
                  <a href="mailto:jamie@futuretech.com" style="color: #3498db; text-decoration: none;">✉️ jamie@futuretech.com</a>
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </section>
    `,
    css: ''
  },

  {
    id: "sleek-dark",
    name: "Sleek Dark",
    html: `
  <section style="font-family: 'Arial', sans-serif;">
    <table cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; border-collapse: collapse;">
      <tr>
        <td style="padding: 20px; background-color: #1a1a1a;">
          <table cellpadding="0" cellspacing="0" style="width: 100%; background-color: #2c2c2c; border-radius: 10px; overflow: hidden;">
            <tr>
              <td style="padding: 20px;">
                <h2 style="margin: 0; font-size: 24px; color: #ffffff;">Morgan Sleek</h2>
                <p style="margin: 5px 0 15px; font-size: 16px; color: #bbbbbb;">Software Architect | DarkCode Inc.</p>
                <table cellpadding="0" cellspacing="0" style="width: 100%;">
                  <tr>
                    <td style="padding: 10px 0; border-top: 1px solid #444444; border-bottom: 1px solid #444444;">
                      <a href="tel:+14567890123" style="color: #e74c3c; text-decoration: none; font-size: 14px;">📞 (456) 789-0123</a>
                    </td>
                  </tr>
                  <tr>
                    <td style="padding-top: 10px;">
                      <a href="mailto:morgan@darkcode.dev" style="color: #e74c3c; text-decoration: none; font-size: 14px;">✉️ morgan@darkcode.dev</a>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </section>
    `,
    css: ''
  }, 
 


{
  id: "midnight-forest-aurora",
  name: "Midnight Forest Aurora",
  html: `
<section style="font-family: 'Segoe UI', sans-serif; background: linear-gradient(135deg, #1b5e20, #2e7d32); padding: 30px; border-radius: 15px; box-shadow: 0 10px 30px rgba(0,0,0,0.4);">
  <div style="background: rgba(0,0,0,0.2); backdrop-filter: blur(5px); border-radius: 10px; padding: 20px; border: 1px solid rgba(255,255,255,0.1); position: relative; overflow: hidden;">
    <div style="position: absolute; top: 0; left: 0; right: 0; height: 5px; background: linear-gradient(90deg, #4caf50, #8bc34a, #cddc39); animation: aurora 3s linear infinite;"></div>
    <h1 style="font-size: 28px; color: #c8e6c9; margin: 20px 0 10px; text-shadow: 0 2px 4px rgba(0,0,0,0.3);">Emma Woodson</h1>
    <p style="font-size: 18px; color: #a5d6a7; margin: 0 0 20px; letter-spacing: 1px;">Environmental Lawyer | GreenLegal</p>
    <div style="display: flex; flex-direction: column; gap: 10px; background: rgba(0,0,0,0.3); border-radius: 8px; padding: 15px;">
      <a href="tel:+13332224444" style="color: #66bb6a; text-decoration: none; font-size: 16px; display: flex; align-items: center;">
        <span style="background: #2e7d32; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">📞</span>
        +1 (333) 222-4444
      </a>
      <a href="mailto:emma@greenlegal.com" style="color: #66bb6a; text-decoration: none; font-size: 16px; display: flex; align-items: center;">
        <span style="background: #2e7d32; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">✉️</span>
        emma@greenlegal.com
      </a>
    </div>
  </div>
</section>
<style>
@keyframes aurora {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}
</style>
  `,
  css: ''
},
{
  id: "minimal-neon-accent",
  name: "Minimal Neon Accent",
  html: `
<section style="font-family: 'Arial', sans-serif; background-color: #ffffff; padding: 30px; border-radius: 15px; box-shadow: 0 10px 30px rgba(0,0,0,0.1);">
  <div style="border-left: 4px solid #3498db; padding-left: 20px; position: relative;">
    <div style="position: absolute; top: 0; left: -4px; width: 4px; height: 40px; background: #3498db; animation: neonPulse 2s ease-in-out infinite;"></div>
    <h1 style="font-size: 28px; color: #333333; margin: 0 0 10px;">Jamie Modern</h1>
    <p style="font-size: 18px; color: #7f8c8d; margin: 0 0 20px;">UX/UI Designer | FutureTech</p>
    <div style="display: flex; flex-direction: column; gap: 10px;">
      <a href="tel:+11234567890" style="color: #3498db; text-decoration: none; font-size: 16px; display: flex; align-items: center; transition: all 0.3s ease;">
        <span style="background: #ecf0f1; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">📱</span>
        +1 (123) 456-7890
      </a>
      <a href="mailto:jamie@futuretech.com" style="color: #3498db; text-decoration: none; font-size: 16px; display: flex; align-items: center; transition: all 0.3s ease;">
        <span style="background: #ecf0f1; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">✉️</span>
        jamie@futuretech.com
      </a>
    </div>
  </div>
</section>
<style>
@keyframes neonPulse {
  0%, 100% { box-shadow: 0 0 5px #3498db, 0 0 10px #3498db; }
  50% { box-shadow: 0 0 20px #3498db, 0 0 30px #3498db; }
}
a:hover {
  transform: translateX(5px);
  color: #2980b9;
}
</style>
  `,
  css: ''
},

{
  id: "neon-cyberpunk",
  name: "Neon Cyberpunk",
  html: `
<section style="font-family: 'Courier New', monospace; background-color: #0c0c0c; padding: 30px; border-radius: 15px; border: 2px solid #00ff00; box-shadow: 0 0 20px rgba(0,255,0,0.5);">
  <div style="background-color: rgba(0,255,0,0.1); border-radius: 10px; padding: 20px; border: 1px solid #00ff00;">
    <h1 style="font-size: 28px; color: #00ff00; margin: 0 0 10px; text-shadow: 0 0 10px #00ff00;">Z3R0_C00L</h1>
    <p style="font-size: 18px; color: #00cc00; margin: 0 0 20px; letter-spacing: 1px;">Cybersecurity Specialist | NeoTech</p>
    <div style="display: flex; justify-content: space-between; align-items: center; background-color: rgba(0,0,0,0.5); border-radius: 8px; padding: 15px; margin-bottom: 15px;">
      <a href="tel:+11010101010" style="color: #00ff00; text-decoration: none; font-size: 16px; display: flex; align-items: center;">
        <span style="background-color: #003300; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">📞</span>
        +1 (101) 010-1010
      </a>
      <a href="mailto:zero@neotech.net" style="color: #00ff00; text-decoration: none; font-size: 16px; display: flex; align-items: center;">
        <span style="background-color: #003300; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; margin-right: 10px;">✉️</span>
        zero@neotech.net
      </a>
    </div>
    <div style="font-size: 12px; color: #009900; text-align: center;">
      "Stay secure in the digital realm"
    </div>
  </div>
</section>
  `,
  css: ''
},

  {
    id: "geometric-burst",
    name: "Geometric Burst",
    html: `
  <section style="font-family: 'Arial', sans-serif;">
    <table cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; border-collapse: collapse;">
      <tr>
        <td style="padding: 20px; background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);">
          <table cellpadding="0" cellspacing="0" style="width: 100%; background-color: rgba(255, 255, 255, 0.9); border-radius: 10px; overflow: hidden;">
            <tr>
              <td style="padding: 20px; position: relative;">
                <div style="position: absolute; top: -30px; right: -30px; width: 100px; height: 100px; background-color: #667eea; transform: rotate(45deg);"></div>
                <div style="position: relative; z-index: 1;">
                  <h2 style="margin: 0; font-size: 24px; color: #333;">Alex Geometric</h2>
                  <p style="margin: 5px 0 15px; font-size: 16px; color: #666;">Design Innovator | ShapeShift Co.</p>
                  <p style="margin: 0; font-size: 14px;">
                    <a href="tel:+11234567890" style="color: #667eea; text-decoration: none;">📞 (123) 456-7890</a>
                  </p>
                  <p style="margin: 5px 0 0; font-size: 14px;">
                    <a href="mailto:alex@shapeshift.com" style="color: #667eea; text-decoration: none;">✉️ alex@shapeshift.com</a>
                  </p>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </section>
    `,
    css: ''
  },
  {
    id: "circular-gradient",
    name: "Circular Gradient",
    html: `
  <section style="font-family: 'Helvetica', sans-serif;">
    <table cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; border-collapse: collapse;">
      <tr>
        <td style="padding: 20px; background: radial-gradient(circle, #48c6ef 0%, #6f86d6 100%);">
          <table cellpadding="0" cellspacing="0" style="width: 100%; background-color: white; border-radius: 50%; overflow: hidden;">
            <tr>
              <td style="padding: 40px 20px; text-align: center;">
                <h2 style="margin: 0; font-size: 28px; color: #333;">Sophia Circle</h2>
                <p style="margin: 10px 0 20px; font-size: 18px; color: #666;">Creative Director | RoundVision</p>
                <p style="margin: 0; font-size: 16px;">
                  <a href="tel:+12345678901" style="color: #48c6ef; text-decoration: none;">🔵 (234) 567-8901</a>
                </p>
                <p style="margin: 10px 0 0; font-size: 16px;">
                  <a href="mailto:sophia@roundvision.com" style="color: #48c6ef; text-decoration: none;">🔵 sophia@roundvision.com</a>
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </section>
    `,
    css: ''
  },
  {
    id: "wave-motion",
    name: "Wave Motion",
    html: `
  <section style="font-family: 'Roboto', sans-serif;">
    <table cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; border-collapse: collapse;">
      <tr>
        <td style="padding: 20px; background-color: #f0f4f8; position: relative; overflow: hidden;">
          <div style="position: absolute; top: 0; left: 0; right: 0; height: 100px; background: #4facfe; transform: skewY(-5deg); transform-origin: top left;"></div>
          <div style="position: relative; z-index: 1; padding-top: 60px;">
            <table cellpadding="0" cellspacing="0" style="width: 100%; background-color: white; border-radius: 10px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
              <tr>
                <td style="padding: 20px;">
                  <h2 style="margin: 0; font-size: 26px; color: #333;">Michael Wave</h2>
                  <p style="margin: 5px 0 15px; font-size: 16px; color: #666;">Ocean Researcher | BlueHorizon Institute</p>
                  <p style="margin: 0; font-size: 14px;">
                    <a href="tel:+13456789012" style="color: #4facfe; text-decoration: none;">🌊 (345) 678-9012</a>
                  </p>
                  <p style="margin: 5px 0 0; font-size: 14px;">
                    <a href="mailto:michael@bluehorizon.org" style="color: #4facfe; text-decoration: none;">🌊 michael@bluehorizon.org</a>
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </td>
      </tr>
    </table>
  </section>
    `,
    css: ''
  },
  {
    id: "hexagon-tech",
    name: "Hexagon Tech",
    html: `
  <section style="font-family: 'Courier New', monospace;">
    <table cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; border-collapse: collapse;">
      <tr>
        <td style="padding: 20px; background-color: #1a1a1a; background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI0MyI+PHBhdGggZD0iTTI1IDAgTDUwIDEyLjUgTDUwIDM3LjUgTDI1IDUwIEwwIDM3LjUgTDAgMTIuNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzMzIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD48L3N2Zz4='); background-size: 50px 43px;">
          <table cellpadding="0" cellspacing="0" style="width: 100%; background-color: rgba(0, 0, 0, 0.8); border: 2px solid #00ff00; border-radius: 15px; overflow: hidden;">
            <tr>
              <td style="padding: 20px; text-align: center;">
                <h2 style="margin: 0; font-size: 24px; color: #00ff00;">&lt;Emma.Code /&gt;</h2>
                <p style="margin: 10px 0 20px; font-size: 16px; color: #00ffff;">AI Engineer | QuantumTech Solutions</p>
                <p style="margin: 0; font-size: 14px;">
                  <a href="tel:+14567890123" style="color: #00ff00; text-decoration: none;">[📞]: (456) 789-0123</a>
                </p>
                <p style="margin: 10px 0 0; font-size: 14px;">
                  <a href="mailto:emma@quantumtech.ai" style="color: #00ff00; text-decoration: none;">[✉️]: emma@quantumtech.ai</a>
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </section>
    `,
    css: ''
  },
  {
    id: "organic-leaves",
    name: "Organic Leaves",
    html: `
  <section style="font-family: 'Georgia', serif;">
    <table cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; border-collapse: collapse;">
      <tr>
        <td style="padding: 20px; background: linear-gradient(135deg, #76b852 0%, #8DC26F 100%);">
          <table cellpadding="0" cellspacing="0" style="width: 100%; background-color: rgba(255, 255, 255, 0.9); border-radius: 15px; overflow: hidden;">
            <tr>
              <td style="padding: 20px; position: relative;">
                <div style="position: absolute; top: -20px; left: -20px; width: 100px; height: 100px; background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj48cGF0aCBkPSJNMCwxMDAgQzAsNDUgNDUsMCAxMDAsMCIgZmlsbD0iIzc2Yjg1MiIgb3BhY2l0eT0iMC4zIj48L3BhdGg+PC9zdmc+'); background-size: cover;"></div>
                <div style="position: absolute; bottom: -20px; right: -20px; width: 100px; height: 100px; background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj48cGF0aCBkPSJNMTAwLDAgQzEwMCw1NSA1NSwxMDAgMCwxMDAiIGZpbGw9IiM4REMyNkYiIG9wYWNpdHk9IjAuMyI+PC9wYXRoPjwvc3ZnPg=='); background-size: cover;"></div>
                <div style="position: relative; z-index: 1;">
                  <h2 style="margin: 0; font-size: 28px; color: #2c5e1a;">Olivia Nature</h2>
                  <p style="margin: 5px 0 15px; font-size: 18px; color: #4a7c40;">Eco Consultant | GreenLife Solutions</p>
                  <p style="margin: 0; font-size: 16px;">
                    <a href="tel:+15678901234" style="color: #76b852; text-decoration: none;">🍃 (567) 890-1234</a>
                  </p>
                  <p style="margin: 5px 0 0; font-size: 16px;">
                    <a href="mailto:olivia@greenlife.com" style="color: #76b852; text-decoration: none;">🍃 olivia@greenlife.com</a>
                  </p>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </section>
    `,
    css: ''
  },
    {
    id: "dark-gradient-minimal",
    name: "Dark Gradient Minimal",
    html: `
<table style="font-family: 'Arial', sans-serif; border-collapse: collapse; width: 100%; max-width: 600px;">
  <tr>
    <td style="padding: 20px; background: linear-gradient(135deg, #2c3e50, #34495e);">
      <table style="width: 100%;">
        <tr>
          <td style="font-size: 20px; font-weight: bold; color: #ecf0f1; padding-bottom: 10px;">Alex Morgan</td>
        </tr>
        <tr>
          <td style="font-size: 14px; color: #bdc3c7; padding-bottom: 15px;">UX Designer | TechInno</td>
        </tr>
        <tr>
          <td style="font-size: 12px; color: #95a5a6;">
            <a href="tel:+11234567890" style="color: #3498db; text-decoration: none;">+1 (123) 456-7890</a> |
            <a href="mailto:alex@techinno.com" style="color: #3498db; text-decoration: none;">alex@techinno.com</a>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
    `,
    css: ''
  },
    {
      id: "gradient-modern",
      name: "Gradient Modern",
      html: `
  <table style="font-family: 'Arial', sans-serif; border-collapse: collapse;">
    <tr>
      <td style="padding: 20px; background: linear-gradient(135deg, #6e8efb, #a777e3);">
        <table style="color: white;">
          <tr>
            <td style="font-size: 24px; font-weight: bold; padding-bottom: 10px;">Jane Doe</td>
          </tr>
          <tr>
            <td style="font-size: 16px; padding-bottom: 20px;">Creative Director | Example Inc.</td>
          </tr>
          <tr>
            <td style="font-size: 14px;">📞 (123) 456-7890</td>
          </tr>
          <tr>
            <td style="font-size: 14px;">✉️ jane.doe@example.com</td>
          </tr>
          <tr>
            <td style="font-size: 14px;">🌐 www.example.com</td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
      `,
      css: ''
    },
    {
      id: "minimalist-line",
      name: "Minimalist Line",
      html: `
  <table style="font-family: 'Helvetica', sans-serif; border-collapse: collapse;">
    <tr>
      <td style="padding: 20px; border-left: 4px solid #3498db;">
        <table style="color: #333;">
          <tr>
            <td style="font-size: 20px; font-weight: bold; padding-bottom: 5px;">Alex Johnson</td>
          </tr>
          <tr>
            <td style="font-size: 14px; color: #777; padding-bottom: 15px;">Senior Developer | Tech Solutions</td>
          </tr>
          <tr>
            <td style="font-size: 14px; padding-bottom: 5px;">📱 (987) 654-3210</td>
          </tr>
          <tr>
            <td style="font-size: 14px; padding-bottom: 5px;">📧 alex.j@techsolutions.com</td>
          </tr>
          <tr>
            <td>
              <a href="https://www.linkedin.com/in/alexj" style="text-decoration: none; margin-right: 10px;">
                <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" alt="LinkedIn" style="width: 20px; height: 20px;">
              </a>
              <a href="https://twitter.com/alexj" style="text-decoration: none;">
                <img src="https://about.twitter.com/content/dam/about-twitter/en/brand-toolkit/brand-download-img-1.jpg.twimg.1920.jpg" alt="Twitter" style="width: 20px; height: 20px;">
              </a>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
      `,
      css: ''
    },
    {
      id: "dark-sleek",
      name: "Dark Sleek",
      html: `
  <table style="font-family: 'Roboto', sans-serif; border-collapse: collapse;">
    <tr>
      <td style="padding: 20px; background-color: #2c3e50; border-radius: 10px;">
        <table style="color: white;">
          <tr>
            <td style="padding-bottom: 15px;">
              <img src="https://via.placeholder.com/80" alt="Profile" style="border-radius: 50%; border: 2px solid #3498db;">
            </td>
          </tr>
          <tr>
            <td style="font-size: 22px; font-weight: bold; padding-bottom: 5px;">Sarah Miller</td>
          </tr>
          <tr>
            <td style="font-size: 14px; color: #bdc3c7; padding-bottom: 15px;">Marketing Specialist | Innovate Co.</td>
          </tr>
          <tr>
            <td style="font-size: 14px; padding-bottom: 5px;">
              <span style="color: #3498db;">Tel:</span> (555) 123-4567
            </td>
          </tr>
          <tr>
            <td style="font-size: 14px; padding-bottom: 5px;">
              <span style="color: #3498db;">Email:</span> sarah.m@innovate.co
            </td>
          </tr>
          <tr>
            <td style="font-size: 14px; padding-bottom: 15px;">
              <span style="color: #3498db;">Web:</span> www.innovate.co
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style="text-decoration: none; background-color: #3498db; color: white; padding: 5px 10px; border-radius: 5px; font-size: 12px;">
                Schedule a Meeting
              </a>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
      `,
      css: ''
    },
    {
      id: "eco-friendly",
      name: "Eco-Friendly",
      html: `
  <table style="font-family: 'Open Sans', sans-serif; border-collapse: collapse;">
    <tr>
      <td style="padding: 20px; background: linear-gradient(135deg, #a8e063, #56ab2f); border-radius: 15px;">
        <table style="color: #333;">
          <tr>
            <td style="font-size: 26px; font-weight: bold; padding-bottom: 10px; color: #1a4314;">Chris Green</td>
          </tr>
          <tr>
            <td style="font-size: 16px; padding-bottom: 20px; color: #333;">Sustainability Consultant | EcoSolutions</td>
          </tr>
          <tr>
            <td style="font-size: 14px; padding-bottom: 5px;">🍃 Reducing carbon footprints since 2010</td>
          </tr>
          <tr>
            <td style="font-size: 14px; padding-bottom: 5px;">📞 (789) 456-1230</td>
          </tr>
          <tr>
            <td style="font-size: 14px; padding-bottom: 5px;">✉️ chris.g@ecosolutions.org</td>
          </tr>
          <tr>
            <td style="font-size: 14px; padding-bottom: 15px;">🌍 www.ecosolutions.org</td>
          </tr>
          <tr>
            <td>
              <div style="font-size: 12px; color: #1a4314; border-top: 1px solid #56ab2f; padding-top: 10px;">
                Please consider the environment before printing this email.
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
      `,
      css: ''
    },
    {
      id: "modern-minimal",
      name: "Modern Minimal",
      html: `
    <section style="font-family: Arial, sans-serif;">
      <table style="width: 100%; max-width: 600px; border-collapse: collapse;">
        <tr>
          <td style="padding: 20px; background-color: #f8f9fa;">
            <table style="width: 100%;">
              <tr>
                <td style="font-size: 24px; font-weight: bold; color: #343a40; padding-bottom: 10px;">Emma Watson</td>
              </tr>
              <tr>
                <td style="font-size: 16px; color: #6c757d; padding-bottom: 15px;">Product Manager | InnovateTech</td>
              </tr>
              <tr>
                <td style="font-size: 14px; color: #495057;">
                  <a href="tel:+12345678901" style="color: #007bff; text-decoration: none;">+1 (234) 567-8901</a> |
                  <a href="mailto:emma@innovatetech.com" style="color: #007bff; text-decoration: none;">emma@innovatetech.com</a>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </section>
      `,
      css: ''
    },
    {
      id: "bold-stripes",
      name: "Bold Stripes",
      html: `
    <section style="font-family: 'Helvetica', sans-serif;">
      <table style="width: 100%; max-width: 600px; border-collapse: collapse;">
        <tr>
          <td style="padding: 20px; background: repeating-linear-gradient(45deg, #f06292, #f06292 10px, #e91e63 10px, #e91e63 20px);">
            <table style="width: 100%; background-color: white; border-radius: 8px; overflow: hidden;">
              <tr>
                <td style="padding: 20px;">
                  <table style="width: 100%;">
                    <tr>
                      <td style="font-size: 22px; font-weight: bold; color: #e91e63; padding-bottom: 10px;">Michael Chen</td>
                    </tr>
                    <tr>
                      <td style="font-size: 16px; color: #333; padding-bottom: 15px;">Graphic Designer | Creative Spark</td>
                    </tr>
                    <tr>
                      <td style="font-size: 14px; color: #666;">
                        <a href="tel:+13456789012" style="color: #e91e63; text-decoration: none;">+1 (345) 678-9012</a> |
                        <a href="mailto:michael@creativespark.com" style="color: #e91e63; text-decoration: none;">michael@creativespark.com</a>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </section>
      `,
      css: ''
    },
    {
      id: "nature-inspired",
      name: "Nature Inspired",
      html: `
    <section style="font-family: 'Georgia', serif;">
      <table style="width: 100%; max-width: 600px; border-collapse: collapse;">
        <tr>
          <td style="padding: 20px; background-color: #e8f5e9; border: 2px solid #4caf50;">
            <table style="width: 100%;">
              <tr>
                <td style="text-align: center; padding-bottom: 15px;">
                  <div style="font-size: 24px; font-weight: bold; color: #2e7d32; border-bottom: 1px solid #4caf50; display: inline-block; padding-bottom: 5px;">
                    Olivia Green
                  </div>
                </td>
              </tr>
              <tr>
                <td style="font-size: 16px; color: #1b5e20; text-align: center; padding-bottom: 15px;">Environmental Scientist | EcoWorld</td>
              </tr>
              <tr>
                <td style="text-align: center; padding-bottom: 10px;">
                  <a href="tel:+14567890123" style="color: #43a047; text-decoration: none; font-size: 14px;">
                    🍃 +1 (456) 789-0123
                  </a>
                </td>
              </tr>
              <tr>
                <td style="text-align: center;">
                  <a href="mailto:olivia@ecoworld.org" style="color: #43a047; text-decoration: none; font-size: 14px;">
                    ✉ olivia@ecoworld.org
                  </a>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </section>
      `,
      css: ''
    },
    {
      id: "tech-wave",
      name: "Tech Wave",
      html: `
    <section style="font-family: 'Courier New', monospace;">
      <table style="width: 100%; max-width: 600px; border-collapse: collapse;">
        <tr>
          <td style="padding: 20px; background-color: #000000; background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMjAiPgo8cmVjdCB3aWR0aD0iMTAwIiBoZWlnaHQ9IjIwIiBmaWxsPSIjMDAwMDAwIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDEwIEMzMCAxMCA3MCAtMTAgMTAwIDEwIEwxMDAgMjAgTDAgMjAiIGZpbGw9IiMwMDRkNDAiPjwvcGF0aD4KPC9zdmc+');">
            <table style="width: 100%;">
              <tr>
                <td style="font-size: 20px; font-weight: bold; color: #00ff00; padding-bottom: 10px;">
                  &lt;Ryan.Code /&gt;
                </td>
              </tr>
              <tr>
                <td style="font-size: 14px; color: #00ffff; padding-bottom: 15px;">Full Stack Developer | TechWave Solutions</td>
              </tr>
              <tr>
                <td style="font-size: 12px; color: #00ff00; padding-bottom: 5px;">
                  <span style="color: #888888;">&lt;phone&gt;</span>
                  <a href="tel:+15678901234" style="color: #00ffff; text-decoration: none;">+1 (567) 890-1234</a>
                  <span style="color: #888888;">&lt;/phone&gt;</span>
                </td>
              </tr>
              <tr>
                <td style="font-size: 12px; color: #00ff00;">
                  <span style="color: #888888;">&lt;email&gt;</span>
                  <a href="mailto:ryan@techwave.dev" style="color: #00ffff; text-decoration: none;">ryan@techwave.dev</a>
                  <span style="color: #888888;">&lt;/email&gt;</span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </section>
      `,
      css: ''
    },
    {
      id: "vintage-flair",
      name: "Vintage Flair",
      html: `
    <section style="font-family: 'Times New Roman', serif;">
      <table style="width: 100%; max-width: 600px; border-collapse: collapse;">
        <tr>
          <td style="padding: 20px; background-color: #f9f5e9; border: 4px double #8b4513;">
            <table style="width: 100%;">
              <tr>
                <td style="text-align: center; padding-bottom: 15px;">
                  <div style="font-size: 28px; font-weight: bold; color: #8b4513; border-bottom: 2px solid #d2b48c; display: inline-block; padding-bottom: 5px;">
                    Eleanor Vintage
                  </div>
                </td>
              </tr>
              <tr>
                <td style="font-size: 18px; color: #a0522d; text-align: center; padding-bottom: 15px; font-style: italic;">Antique Curator | Timeless Treasures</td>
              </tr>
              <tr>
                <td style="text-align: center; padding-bottom: 10px;">
                  <a href="tel:+16789012345" style="color: #8b4513; text-decoration: none; font-size: 16px;">
                    ☎ +1 (678) 901-2345
                  </a>
                </td>
              </tr>
              <tr>
                <td style="text-align: center;">
                  <a href="mailto:eleanor@timelesstreasures.com" style="color: #8b4513; text-decoration: none; font-size: 16px;">
                    ✉ eleanor@timelesstreasures.com
                  </a>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </section>
      `,
      css: ''
    }
  ];