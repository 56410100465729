import React, { useState, useEffect, useRef } from 'react';
import { Button } from "./components/ui/button";
import { Card, CardContent } from "./components/ui/card";
import { Input } from "./components/ui/input";
import { Loader2 } from "lucide-react";
import generateEmailSignature from './utils/generateSignature';
import { Sparkles} from 'lucide-react';
const EmailSignatureChat = ({ onSignatureGenerated, credits, setCredits }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isChatStarted, setIsChatStarted] = useState(false);
  const chatEndRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() && credits > 0) {
      if (!isChatStarted) {
        setIsChatStarted(true);
      }
      setMessages([...messages, { type: 'user', content: input }]);
      setInput('');
      setIsGenerating(true);
      setCredits(prev => prev - 10);

      try {
        const generatedHTML = await generateEmailSignature(input);
        setMessages(prev => [...prev, 
          { type: 'system', content: 'Email signature generation completed.' }
        ]);
        onSignatureGenerated(generatedHTML);
      } catch (error) {
        setMessages(prev => [...prev, 
          { type: 'system', content: 'Error generating signature: ' + error.message }
        ]);
      } finally {
        setIsGenerating(false);
      }
    }
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className={`${isChatStarted ? 'h-[600px]' : 'h-auto'}`}>
      <Card className={`${isChatStarted ? 'flex-grow overflow-hidden flex flex-col' : ''}`}>
        {isChatStarted && (
          <CardContent className="flex-grow overflow-y-auto p-4">
            {messages.map((message, index) => (
              <div key={index} className={`mb-4 ${message.type === 'user' ? 'text-right' : 'text-left'}`}>
                <span className={`inline-block p-2 rounded-lg ${message.type === 'user' ? 'bg-primary text-primary-foreground' : 'bg-secondary text-secondary-foreground'}`}>
                  {message.content}
                </span>
              </div>
            ))}
            {isGenerating && (
              <div className="text-left mb-4">
                <span className="inline-block p-2 rounded-lg bg-secondary text-secondary-foreground">
                  Generating email signature...
                </span>
              </div>
            )}
            <div ref={chatEndRef} />
          </CardContent>
        )}
        <form onSubmit={handleSubmit} className={`p-4 ${isChatStarted ? 'border-t' : ''}`}>
          <div className="flex items-center">
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Describe your email signature..."
              className="flex-grow"
              disabled={isGenerating || credits <= 0}
            />
            <Button type="submit" className="ml-2" disabled={isGenerating || credits <= 0}>
              {isGenerating ? <Loader2 className="h-4 w-4 animate-spin" /> : 'Send'}
            </Button>
            <div className="ml-4 text-sm font-medium">
              {credits}/100 Credits
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default EmailSignatureChat;