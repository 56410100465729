import React, { useEffect } from 'react';

const MatomoTracker = () => {
  useEffect(() => {
    // Inizializza Matomo
    window._paq = window._paq || [];
    
    // Traccia la visualizzazione della pagina
    window._paq.push(['trackPageView']);
    
    // Abilita il tracciamento dei link
    window._paq.push(['enableLinkTracking']);
    
    // Inizializza il tracker
    (function() {
      var u="//analytics.firewallsrl.com/";
      window._paq.push(['setTrackerUrl', u+'matomo.php']);
      window._paq.push(['setSiteId', '14']);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();
  }, []);

  return null; // Questo componente non renderizza nulla
};

export default MatomoTracker;